<template>
  <v-container fluid>
    <v-toolbar rounded class="mb-4">
      <v-toolbar-title class="text-h5">Tableau de bord</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn small color="primary" @click="openModalCreate">Créer une carte</v-btn>
    </v-toolbar>
    <!-- Ajout des filtres -->
    <v-row class="mx-2 d-flex align-center">
      <v-col cols="12" sm="2">
        <v-text-field
          v-model="searchQuery"
          append-icon="mdi-magnify"
          label="Rechercher"
          single-line
          hide-details
          @click:append="performSearch"
          @keyup.enter="performSearch"
          clearable
          solo
          dense
        ></v-text-field>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="2">
        <v-autocomplete
          v-model="selectedPerson"
          :items="peopleList"
          label="Filtrer par créateur"
          clearable
          @change="applyFilters"
          solo
          dense
          hide-details
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="12" sm="2">
        <v-autocomplete
          v-model="selectedModule"
          :items="moduleList"
          label="Filtrer par module"
          clearable
          @change="applyFilters"
          solo
          hide-details
          dense
        ></v-autocomplete>
      </v-col>
    </v-row>

    <v-container fluid class="d-flex board-container">
      <v-alert v-if="statusEnCours.length === 0 && searchQuery" type="info" class="mx-4"
        >Aucun résultat trouvé pour la recherche "{{ searchQuery }}"</v-alert
      >
      <v-alert
        v-if="statusEnCours.length === 0 && !searchQuery && statusTermine.length === 0"
        type="info"
        class="mx-4"
        >Aucune carte en cours</v-alert
      >
      <div class="scrollable-columns" @wheel="scrollHorizontally">
        <!-- Colonnes pour les cartes en cours -->
        <div
          v-for="(cards, key) in groupCardsByModule(statusEnCours)"
          :key="key"
          class="column mx-2"
        >
          <v-card
            class="column-content"
            :class="$vuetify.theme.dark ? 'dark' : ''"
            min-width="300"
            max-height="calc(90vh - 120px)"
            elevation="2"
          >
            <v-toolbar dense color="primary" dark height="38">
              <h5 class="text-center column-title">
                {{ formatColumnTitle(key) }} {{ cardCountByColumn[key] }}
              </h5>
              <v-spacer></v-spacer>
              <v-btn icon @click="openModalListeDemande">
                <v-icon>mdi-format-list-bulleted</v-icon>
              </v-btn>
            </v-toolbar>

            <draggable
              class="card-list px-2 py-3"
              :list="cards"
              group="board"
              @change="handleCardMove"
            >
              <Carte
                v-for="card in cards"
                :key="card.id"
                :card="card"
                :isTermine="false"
                @assign-task="openAssignTaskDialog"
                @unassign-task="unassignTask"
                @estimate-duration="openEstimateDurationDialog"
                @date-previsionnel="openDatePrevisionnelDialog"
                @open-detail="openDetail"
                @update-card="openModalUpdate"
                @delete-card="openModalDelete"
                @open-chat="openChat"
              />
            </draggable>
          </v-card>
        </div>
      </div>

      <!-- Colonne pour les cartes terminées -->
      <div class="column fixed-column">
        <v-card
          class="column-content"
          :class="$vuetify.theme.dark ? 'dark' : ''"
          min-width="300"
        >
          <v-toolbar dense color="success" dark height="38">
            <h5 class="text-center column-title" id="dest">Terminé</h5>
            <h5 class="ml-1">{{ cardCountByColumn["Terminé"] }}</h5>
          </v-toolbar>
          <draggable class="card-list px-2 py-3" :list="statusTermine" group="board">
            <Carte
              v-for="card in statusTermine"
              :key="card.id"
              :card="card"
              :isTermine="true"
              @reset-status="resetStatus"
              @open-detail="openDetail"
              @delete-card="openModalDelete"
              @open-chat="openChat"
            />
          </draggable>
        </v-card>
      </div>
    </v-container>

    <!-- Modales -->
    <v-dialog v-model="createDev" max-width="50%">
      <CreateDev @close-create="createDev = false" @refresh="loadData" />
    </v-dialog>

    <v-dialog v-model="modalListeDemande" max-width="90%">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Liste des demandes</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="modalListeDemande = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-data-table
          :headers="headers"
          :items="taskSupportVision"
          :items-per-page="5"
          sort-by="carte.impactType"
          sort-desc
          class="elevation-1"
        >
          <!-- Contenu du tableau -->
        </v-data-table>
      </v-card>
    </v-dialog>

    <v-dialog v-model="assignTaskDialog" max-width="500px">
      <v-card>
        <v-card-title>Assigner la tâche</v-card-title>
        <v-card-text>
          <v-autocomplete
            v-model="selectedUser"
            :items="users"
            :item-text="(item) => `${item.firstname} ${item.lastname}`"
            label="Sélectionner un développeur"
            outlined
            dense
            hide-details
            class="mb-4"
          >
            <template v-slot:selection="{ item }">
              {{ item.firstname + " " + item.lastname }}
            </template>
            <template v-slot:item="{ item }">
              {{ item.firstname + " " + item.lastname }}
            </template>
          </v-autocomplete>

          <v-row>
            <v-col cols="12" sm="6">
              <v-menu
                v-model="dateDebutMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :value="assignmentDateDebut | formatDate"
                    label="Date de début"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="assignmentDateDebut"
                  @input="dateDebutMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6">
              <v-menu
                ref="menuDebutTime"
                v-model="timeDebutMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="timeDebut"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="timeDebut"
                    label="Heure de début"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="timeDebutMenu"
                  v-model="timeDebut"
                  full-width
                  @click:minute="$refs.menuDebutTime.save(timeDebut)"
                ></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6">
              <v-menu
                v-model="dateFinMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :value="assignmentDateFin | formatDate"
                    label="Date de fin"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="assignmentDateFin"
                  @input="dateFinMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6">
              <v-menu
                ref="menuFinTime"
                v-model="timeFinMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="timeFin"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="timeFin"
                    label="Heure de fin"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="timeFinMenu"
                  v-model="timeFin"
                  full-width
                  @click:minute="$refs.menuFinTime.save(timeFin)"
                ></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="assignTaskDialog = false"
            >Annuler</v-btn
          >
          <v-btn color="blue darken-1" text @click="assignTask">Assigner</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modaleUpdate" max-width="50%">
      <EditDev @close-edit="modaleUpdate = false" @refresh="loadData" />
    </v-dialog>

    <v-dialog v-model="modaleDelete" max-width="30%">
      <DeleteDev @close-delete-modal="modaleDelete = false" @dev-deleted="loadData" />
    </v-dialog>

    <v-dialog v-model="modaleDetails" max-width="50%">
      <CarteDetails @close="modaleDetails = false" @refresh="loadData" />
    </v-dialog>

    <!-- Nouvelles modales pour l'assignation, la durée estimée et la date prévisionnelle -->
    <!-- modale de liste des demandes -->
    <v-dialog v-model="modalListeDemande" max-width="90%">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Liste des demandes</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="modalListeDemande = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <!-- trier par impact desc -->
        <v-data-table
          :headers="headers"
          :items="taskSupportVision"
          :items-per-page="5"
          sort-by="carte.impactType"
          sort-desc
          class="elevation-1"
        >
          <!-- si non assigné afficher non assigné -->
          <template
            v-slot:item.carte.assignement
            &&
            item.carte.assignement.assignedTo="{ item }"
          >
            {{
              item.carte.assignement ? item.carte.assignement.assignedTo : "Non assigné"
            }}
          </template>

          <!-- afficher la duree au bon format avec filtre -->
          <template v-slot:item.carte.assignement.duree="{ item }">
            <p v-if="item.carte.assignement && item.carte.assignement.duree">
              {{ item.carte.assignement.duree | formatDuration }}
            </p>
            <p v-else>Non assigné</p>
          </template>

          <!-- afficher dans v-chip de couleur les type d impact -->
          <template v-slot:item.carte.impactType="{ item }">
            <v-chip :color="getColorByDemande(item.carte.impactType)" dark small>
              {{ item.carte.impactType }}
            </v-chip>
          </template>

          <template v-slot:item.carte.assignement.status="{ item }">
            <v-chip
              v-if="item.carte.assignement && item.carte.assignement.status"
              :color="getColorByStatus(item.carte.assignement.status)"
              dark
              small
            >
              {{ item.carte.assignement.status }}
            </v-chip>
            <v-chip v-else color="black" dark small> Aucun </v-chip>
          </template>

          <!-- si pas de sous module afficher aucun -->
          <template v-slot:item.carte.sous_module="{ item }">
            {{
              item.carte.sous_module && item.carte.sous_module.length > 0
                ? formatData(item.carte.sous_module)
                : "Aucun"
            }}
          </template>

          <template v-slot:item.action="{ item }">
            <div class="d-flex">
              <!-- unassign task -->
              <v-btn
                v-if="item.carte.assignement && item.carte.assignement.assignedTo"
                icon
                small
                color="primary"
                dark
                @click="unassignTask(item._id)"
              >
                <v-icon>mdi-account-minus</v-icon>
              </v-btn>
              <v-btn icon small color="warning" dark @click="openModalUpdate(item._id)">
                <v-icon>mdi-square-edit-outline</v-icon>
              </v-btn>
              <v-btn icon small color="error" dark @click="openModalDelete(item._id)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>

    <v-dialog v-model="estimateDurationDialog" max-width="400px">
      <v-card>
        <v-card-title>Estimer la durée</v-card-title>
        <v-card-text>
          <v-text-field
            v-model.number="estimatedDuration"
            label="Durée"
            type="number"
          ></v-text-field>
          <v-select
            v-model="durationUnit"
            :items="[
              { text: 'Heures', value: 'heures' },
              { text: 'Jours', value: 'jours' },
              { text: 'Semaines', value: 'semaines' },
            ]"
            label="Unité"
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="estimateDurationDialog = false"
            >Annuler</v-btn
          >
          <v-btn color="blue darken-1" text @click="addEstimatedDuration">Valider</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="datePrevisionnelDialog" max-width="340px">
      <v-card>
        <v-card-title>Définir la date prévisionnelle</v-card-title>
        <v-card-text>
          <v-date-picker v-model="previsionnelDate"></v-date-picker>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="datePrevisionnelDialog = false"
            >Annuler</v-btn
          >
          <v-btn color="blue darken-1" text @click="addDatePrevisionnel">Valider</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- carte chat -->
    <v-dialog v-model="carteChat" max-width="60%">
      <CarteChat
        @close="carteChat = false"
        :card="selectedCard"
        :users="users"
        :location="location"
        @refresh="loadData"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import draggable from "vuedraggable";
import moment from "moment";
import ClientService from "@/Services/SupportVision/ClientService";
import BasesService from "@/Services/SupportVision/BasesService";
import DeveloppementService from "@/Services/SupportVision/DeveloppementService";
import UserService from "@/Services/UserService";
import SupportVisionBus from "@/Components/Views/SupportVision/SupportVisionBus";
import Mark from "mark.js";
import ProjectBus from "../../ProjectBus";
import Carte from "./carte.vue";

export default {
  name: "TrelloBoard",
  components: {
    draggable,
    CreateDev: () => import("@/Components/Views/SupportVision/Cartes/createDev"),
    EditDev: () => import("@/Components/Views/SupportVision/Cartes/editDev"),
    DeleteDev: () => import("@/Components/Views/SupportVision/Cartes/devDelete"),
    CarteDetails: () =>
      import("@/Components/Views/SupportVision/Cartes/carteDetails.vue"),
    CarteChat: () => import("@/Components/Views/SupportVision/Cartes/carteChat.vue"),
    Carte,
  },
  data() {
    return {
      createDev: false,
      cardShow: {},
      users: [],
      modalListeDemande: false,
      modaleUpdate: false,
      modaleDelete: false,
      modaleDetails: false,
      assignTaskDialog: false,
      estimateDurationDialog: false,
      datePrevisionnelDialog: false,
      selectedCard: null,
      selectedUser: null,
      assignmentDateDebut: null,
      assignmentDateFin: null,
      estimatedDuration: null,
      timeDebutMenu: false,
      timeFinMenu: false,
      dateDebutMenu: false,
      dateFinMenu: false,
      timeDebut: null,
      timeFin: null,
      durationUnit: "heures",
      previsionnelDate: null,
      headers: [
        { text: "Libellé", value: "carte.libelle", width: "15%" },
        { text: "Assigné", value: "carte.assignement.assignedTo", width: "15%" },
        { text: "Durée", value: "carte.assignement.duree", width: "15%" },
        { text: "Impact", value: "carte.impactType" },
        {
          text: "Statut",
          value: "carte.assignement.status",
          sortable: false,
          width: "15%",
        },
        { text: "Type de développement", value: "carte.devType", width: "10%" },
        { text: "Origine", value: "carte.originType", width: "10%" },
        { text: "Module", value: "carte.module", width: "10%" },
        { text: "Sous module", value: "carte.sous_module", width: "15%" },
        {
          text: "Action",
          value: "action",
          sortable: false,
          align: "center",
          width: "10%",
        },
      ],
      searchQuery: "",
      searchResults: [],
      markInstance: null,
      selectedPerson: null,
      selectedModule: null,
      peopleList: [],
      moduleList: [],
      carteChat: false,
      location: "",
    };
  },
  // formater les date et durée avec moment et filter
  filters: {
    formatDate(value) {
      if (!value) return "";
      return moment(value).format("DD/MM/YYYY");
    },
  },
  computed: {
    ...mapState(["taskSupportVision"]),
    getColorByStatus() {
      return (status) => {
        if (status == "en_cours") {
          return "orange";
        } else if (status == "terminé") {
          return "green";
        } else if (status == "prise_en_compte") {
          return "grey";
        } else if (status == undefined || status == "") {
          return "black";
        }
      };
    },
    statusEnCours() {
      let filteredTasks = this.taskSupportVision.filter(
        (item) =>
          !item.carte.assignement ||
          !item.carte.assignement.status ||
          item.carte.assignement.status !== "terminé"
      );

      filteredTasks = this.applyFiltersToTasks(filteredTasks);

      if (this.searchQuery) {
        const query = this.searchQuery.toLowerCase();
        filteredTasks = filteredTasks.filter(
          (task) =>
            task.carte.libelle.toLowerCase().includes(query) ||
            (task.carte.assignement &&
              task.carte.assignement.assignedTo &&
              task.carte.assignement.assignedTo.toLowerCase().includes(query)) ||
            task.carte.module.toLowerCase().includes(query) ||
            task.carte.impactType.toLowerCase().includes(query) ||
            task.carte.devType.toLowerCase().includes(query) ||
            task.carte.originType.toLowerCase().includes(query) ||
            task.carte.sous_module.toLowerCase().includes(query) ||
            task.carte.userCreate.toLowerCase().includes(query)
        );
      }

      return filteredTasks;
    },
    statusTermine: {
      get() {
        let termineTasks = this.taskSupportVision.filter(
          (item) => item.carte.assignement && item.carte.assignement.status === "terminé"
        );

        termineTasks = this.applyFiltersToTasks(termineTasks);

        if (this.searchQuery) {
          const query = this.searchQuery.toLowerCase();
          termineTasks = termineTasks.filter(
            (task) =>
              task.carte.libelle.toLowerCase().includes(query) ||
              (task.carte.assignement &&
                task.carte.assignement.assignedTo &&
                task.carte.assignement.assignedTo.toLowerCase().includes(query)) ||
              task.carte.module.toLowerCase().includes(query) ||
              task.carte.impactType.toLowerCase().includes(query) ||
              task.carte.devType.toLowerCase().includes(query) ||
              task.carte.originType.toLowerCase().includes(query) ||
              task.carte.sous_module.toLowerCase().includes(query) ||
              task.carte.userCreate.toLowerCase().includes(query)
          );
        }

        return termineTasks;
      },
      set(value) {
        // this.statusTermine = value;
      },
    },
    cardCountByColumn() {
      const counts = {};
      for (const [key, cards] of Object.entries(
        this.groupCardsByModule(this.statusEnCours)
      )) {
        counts[key] = cards.length;
      }
      counts["Terminé"] = this.statusTermine.length;
      return counts;
    },
  },
  watch: {
    searchQuery() {
      this.highlightSearchResults();
    },
  },
  methods: {
    ...mapMutations([
      "setTaskSupportVision",
      "setTaskId",
      "setClientList",
      "setBaseList",
    ]),
    performSearch() {
      this.searchResults = this.filteredTasks;
    },
    openChat(card) {
      this.selectedCard = card;
      this.carteChat = true;
    },
    initializeFilterLists() {
      // Créer la liste des personnes pour le filtre
      this.peopleList = this.users.map((user) => ({
        text: `${user.firstname} ${user.lastname}`,
        value: user._id, // Supposons que chaque utilisateur a un ID unique
      }));

      // Créer la liste des modules pour le filtre
      this.moduleList = [
        ...new Set(this.taskSupportVision.map((task) => task.carte.module)),
      ];
    },
    applyFilters() {
      // Cette méthode sera appelée chaque fois qu'un filtre change
      this.performSearch();
    },
    applyFiltersToTasks(tasks) {
      return tasks.filter((task) => {
        const matchesPerson =
          !this.selectedPerson || task.carte.userCreate === this.selectedPerson;
        const matchesModule =
          !this.selectedModule || task.carte.module === this.selectedModule;
        const matchesSearch =
          !this.searchQuery ||
          task.carte.libelle.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          (task.carte.assignement &&
            task.carte.assignement.assignedTo &&
            task.carte.assignement.assignedTo
              .toLowerCase()
              .includes(this.searchQuery.toLowerCase())) ||
          task.carte.module.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          task.carte.impactType.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          task.carte.devType.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          task.carte.originType.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          (task.carte.sous_module &&
            task.carte.sous_module
              .toLowerCase()
              .includes(this.searchQuery.toLowerCase())) ||
          task.carte.userCreate.toLowerCase().includes(this.searchQuery.toLowerCase());

        return matchesPerson && matchesModule && matchesSearch;
      });
    },
    highlightSearchResults() {
      this.$nextTick(() => {
        const searchables = document.querySelectorAll(".searchable");
        searchables.forEach((el) => {
          const text = el.textContent;
          if (
            this.searchQuery &&
            text.toLowerCase().includes(this.searchQuery.toLowerCase())
          ) {
            const regex = new RegExp(`(${this.searchQuery})`, "gi");
            el.innerHTML = text.replace(regex, "<mark>$1</mark>");
          } else {
            el.innerHTML = text;
          }
        });
      });
    },
    async loadData() {
      const res = await DeveloppementService.getDev();
      const baseUrl = res.config.baseURL + res.config.url;
      this.location = baseUrl;
      this.setTaskSupportVision(res.data);
      this.users = await UserService.getUsers();
      const clientsList = await ClientService.getClientList();
      const baseList = await BasesService.getBasesList();
      this.$store.commit("setClientList", clientsList);
      this.$store.commit("setBaseList", baseList);
      // Initialiser les listes pour les filtres
      this.initializeFilterLists();
      this.taskSupportVision.forEach((task) => {
        this.$set(task, "highlighted", false);
      });
      this.highlightSearchResults();
    },
    openModalCreate() {
      this.createDev = true;
    },
    openModalUpdate(id) {
      this.setTaskId(id);
      this.modaleUpdate = true;
    },
    openModalDelete(id) {
      this.setTaskId(id);
      this.modaleDelete = true;
    },
    openDetail(id) {
      this.setTaskId(id);
      this.modaleDetails = true;
      SupportVisionBus.$emit("open-details");
    },
    toggleCardShow(card) {
      this.$set(this.cardShow, card._id, !this.cardShow[card._id]);
    },
    formatColumnTitle(key) {
      const [module, sousModule] = key.split("-");
      return `${module} - ${sousModule}`;
    },
    getColorByDemande(demande) {
      const colors = {
        "Travaux unique": "#43A047",
        Release: "#1E88E5",
        Urgent: "#E53935",
      };
      return colors[demande] || "#7b4e8e";
    },
    isCardLinked(card) {
      return (
        (card.carte.linkedCard && card.carte.linkedCard !== "null") ||
        card.carte.linkedCard !== ""
      );
    },
    isCardAssigned(card) {
      return card.carte.assignement && card.carte.assignement.assigned;
    },
    formatData(data) {
      if (Array.isArray(data)) {
        return data.join(", ");
      }
      return data ? data.replace(/[\][\]"]+/g, "") : "";
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    formatDuration(duration) {
      return moment.duration(duration).humanize();
    },
    scrollHorizontally(event) {
      event.currentTarget.scrollLeft += event.deltaY;
    },
    async handleCardMove(event) {
      const card = event.removed ? event.removed.element : event.added.element;
      const colDest = document.getElementById("dest");

      const isMovingToTermine = colDest && colDest.innerText === "Terminé";

      // Vérification des cartes liées
      if (
        card.carte.linkedCard &&
        card.carte.linkedCard !== "null" &&
        !card.carte.linkedCardFinished
      ) {
        // Vérifier si on essaie de déplacer vers "Terminé"
        if (isMovingToTermine) {
          this.$nError(
            `Vous ne pouvez pas mettre cette carte en terminé avant de terminer la carte liée "${card.carte.linkedCard}"`
          );
          await this.loadData();
          return;
        }
      }

      if (isMovingToTermine) {
        if (!card.carte.assignement) {
          card.carte.assignement = {};
        }
        card.carte.assignement.status = "terminé";
        try {
          await DeveloppementService.statusChange(card._id, card.carte.assignement);
        } catch (error) {
          console.error("Erreur lors de la mise à jour du statut:", error);
          this.$nError("Erreur lors de la mise à jour du statut de la carte");
          await this.loadData();
          return;
        }
      } else if (event.added) {
        // Si la carte est déplacée hors de la colonne "Terminé"
        if (card.carte.assignement && card.carte.assignement.status === "terminé") {
          card.carte.assignement.status = "en_cours";
          try {
            await DeveloppementService.statusChange(card._id, card.carte.assignement);
          } catch (error) {
            console.error("Erreur lors de la mise à jour du statut:", error);
            this.$nError("Erreur lors de la mise à jour du statut de la carte");
            await this.loadData();
            return;
          }
        }
      }
      // Mise à jour des données
      await this.loadData();
    },
    async updateCardStatus(card, status) {
      if (!card.carte.assignement) {
        card.carte.assignement = {};
      }
      card.carte.assignement.status = status;
      try {
        await DeveloppementService.statusChange(card._id, card.carte.assignement);
      } catch (error) {
        console.error("Erreur lors de la mise à jour du statut:", error);
        this.$nError("Erreur lors de la mise à jour du statut de la carte");
      }
    },
    groupCardsByModule(cards) {
      return cards.reduce((acc, card) => {
        const module = card.carte.module;
        const sousModule = card.carte.sous_module || "Aucun";
        const key = `${module}-${sousModule}`;

        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(card);
        return acc;
      }, {});
    },
    openModalListeDemande() {
      this.modalListeDemande = true;
    },
    openAssignTaskDialog(card) {
      this.selectedCard = card;
      this.selectedUser = null;
      this.assignmentDateDebut = null;
      this.assignmentDateFin = null;
      this.assignTaskDialog = true;
      this.timeDebut = null;
      this.timeFin = null;
    },
    async unassignTask(id) {
      await DeveloppementService.unassignTask(id);
      this.loadData();
      this.$nInfo("Tâche désassignée avec succès");
    },
    openEstimateDurationDialog(card) {
      this.selectedCard = card;
      this.estimateDurationDialog = true;
    },
    openDatePrevisionnelDialog(card) {
      this.selectedCard = card;
      this.previsionnelDate = null; // Réinitialiser la date
      this.datePrevisionnelDialog = true;
    },
    async resetStatus(id) {
      await DeveloppementService.resetStatus(id);
      this.loadData();
      this.$nInfo("Tâche remise en cours avec succès");
    },
    async assignTask() {
      if (
        this.selectedCard &&
        this.selectedUser &&
        this.assignmentDateDebut &&
        this.assignmentDateFin &&
        this.timeDebut &&
        this.timeFin
      ) {
        const dateDebut = moment(`${this.assignmentDateDebut} ${this.timeDebut}`).format(
          "YYYY-MM-DDTHH:mm"
        );
        const dateFin = moment(`${this.assignmentDateFin} ${this.timeFin}`).format(
          "YYYY-MM-DDTHH:mm"
        );

        const data = {
          assignement: {
            dateAssignement: moment().format("YYYY-MM-DD HH:mm:ss"),
            dateFin: dateFin,
            dateDebut: dateDebut,
            assignedTo: this.selectedUser,
            assigned: true,
          },
        };

        try {
          await DeveloppementService.assignTask(this.selectedCard._id, data);
          this.assignTaskDialog = false;
          await this.loadData();
          this.$nInfo("Tâche assignée avec succès");
        } catch (error) {
          console.error("Erreur lors de l'assignation de la tâche:", error);
          this.$nError("Erreur lors de l'assignation de la tâche");
        }
      } else {
        this.$nError("Veuillez remplir tous les champs");
      }
    },
    async addEstimatedDuration() {
      if (this.selectedCard && this.estimatedDuration > 0) {
        try {
          const data = {
            duree: this.estimatedDuration,
            unite: this.durationUnit,
          };
          await DeveloppementService.addDuration(this.selectedCard._id, data);
          this.estimateDurationDialog = false;
          await this.loadData(); // Recharger les données
          this.$nInfo("Durée estimée ajoutée avec succès");
        } catch (error) {
          console.error("Erreur lors de l'ajout de la durée estimée:", error);
          this.$nError("Erreur lors de l'ajout de la durée estimée");
        }
      } else {
        this.$nError("Veuillez entrer une durée valide");
      }
    },
    async addDatePrevisionnel() {
      if (!this.selectedCard) {
        console.error("Aucune carte sélectionnée");
        this.$nError("Erreur: Aucune carte sélectionnée");
        return;
      }

      if (!this.previsionnelDate) {
        console.error("Aucune date sélectionnée");
        this.$nError("Veuillez sélectionner une date");
        return;
      }

      try {
        const data = {
          previsionDate: this.previsionnelDate,
        };

        const response = await DeveloppementService.addDatePrevisionnel(
          this.selectedCard._id,
          data
        );

        this.datePrevisionnelDialog = false;
        await this.loadData();
        this.$nInfo("Date prévisionnelle ajoutée avec succès");
      } catch (error) {
        console.error("Erreur lors de l'ajout de la date prévisionnelle:", error);
        this.$nError("Erreur lors de l'ajout de la date prévisionnelle");
      }
    },
  },
  async mounted() {
    this.loadData();
    ProjectBus.$on("close-create");
  },
  updated() {
    this.highlightSearchResults();
  },
};
</script>

<style scoped>
.board-container {
  display: flex;
  overflow: hidden;
  padding-bottom: 16px;
}

.scrollable-columns {
  display: flex;
  overflow-x: auto;
  flex-grow: 1;
  padding-bottom: 16px;
}

.column {
  min-width: 300px;
}

.fixed-column {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}

.column-content {
  background-color: #f5f5f5;
}
.column-content.dark {
  background-color: #424242;
}

.column-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-list {
  min-height: 10px;
  max-height: calc(90vh - 180px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}

.linked-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background-color: #7b4e8e;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

/* Styles pour la barre de défilement */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.highlighted {
  box-shadow: 0 0 10px rgba(0, 0, 255, 0.5);
  transform: scale(1.02);
  transition: all 0.3s ease;
}
mark {
  background-color: yellow !important;
  color: black !important;
  padding: 0 2px !important;
  border-radius: 2px !important;
}

/* Pour s'assurer que le surlignage fonctionne même sur le texte blanc */
.white--text mark {
  color: black !important;
}
</style>
